
































































.about-us {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    min-height: 70vh;
    padding: 180px 0 50px;
    position: relative;
    .content-wyb {
      color: #fff;
      padding: 0rem 2rem;
      text-align: justify;
      p {
        font-weight: 400;
        letter-spacing: 0.3px;
        line-height: 110%;
      }
    }
    .content-img-wyb-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      max-width: 800px;
      img {
        // max-height: 385px;
        height: auto;
        width: 100%;
        border-radius: 5px;
      }
    }
    .box-information {
      background: #fff;
      border-radius: 25px;
      box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.3);
      position: relative;
      padding: 40px 80px;
      max-width: 1024px;
      width: calc(100% - 15px);
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 6px;
        width: 60%;
        background: #66ac08;
        border-radius: 20px;
        margin: auto;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -3px;
        height: 6px;
        width: 60%;
        background: #66ac08;
        border-radius: 20px;
        margin: auto;
      }
      .item-info {
        margin-bottom: 15px;
        .title-info {
          font-size: clamp(17px, 3vw, 19px);
          font-weight: 600;
          line-height: 120%;
          color: #28a745;
        }
        .detail-info {
          font-size: clamp(15px, 3vw, 17px);
          font-weight: 600;
          line-height: 120%;
        }
      }
    }
    @media screen and (min-width: 1441px) {
      .content-wyb {
        p {
          font-size: 1.125rem;
          line-height: 150%;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 150px 0 40px;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .content-wyb {
        p {
          font-size: 1rem;
          margin-bottom: 15px;
          line-height: 140%;
        }
      }
      .box-information {
        padding: 15px 30px;
        width: auto;
      }
    }

    @media (max-width: 767px) {
      padding: 125px 0 55px;
      min-height: 300px;
      .content-img-wyb-1 {
        display: none;
      }
      .content-wyb {
        text-align: center;
        padding: 20px 15px;
        p {
          margin: auto;
          text-align: center;
        }
      }
    }
  }
  .section-2 {
    padding: 0px 0 10px;
  }
}
